import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { selectAuthState } from "app/auth/authSlice";
import { useAppSelector } from "app/hooks";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "components/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import PageArchive from "containers/PageArchive/PageArchive";
import PageAuthor from "containers/PageAuthor/PageAuthor";
import PageSearch from "containers/PageSearch/PageSearch";
import PageSingle from "containers/PageSingle/PageSingle";
import PageSingleHasSidebar from "containers/PageSingle/PageSingleHasSidebar";
import PageSingleTemplate2 from "containers/PageSingle/PageSingleTemp2";
import PageSingleTemp2Sidebar from "containers/PageSingle/PageSingleTemp2Sidebar";
import PageSingleTemplate3 from "containers/PageSingle/PageSingleTemp3";
import PageSingleTemp3Sidebar from "containers/PageSingle/PageSingleTemp3Sidebar";
import PageAbout from "containers/PageAbout/PageAbout";
import PageContact from "containers/PageContact/PageContact";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageForgotPass from "containers/PageForgotPass/PageForgotPass";
import PageDashboard from "containers/PageDashboard/PageDashboard";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import HeaderContainer from "containers/HeaderContainer/HeaderContainer";
import PageHome from "containers/PageHome/PageHome";
import PageHomeDemo2 from "containers/PageHome/PageHomeDemo2";
import PageHomeDemo3 from "containers/PageHome/PageHomeDemo3";
import PageAuthorV2 from "containers/PageAuthor/PageAuthorV2";
import PageHomeDemo4 from "containers/PageHome/PageHomeDemo4";
import PageSearchV2 from "containers/PageSearch/PageSearchV2";
import PageSingleGallery from "containers/PageSingleGallery/PageSingleGallery";
import PageSingleAudio from "containers/PageSingleAudio/PageSingleAudio";
import PageSingleVideo from "containers/PageSingleVideo/PageSingleVideo";
import PageArchiveVideo from "containers/PageArchive/PageArchiveVideo";
import PageArchiveAudio from "containers/PageArchive/PageArchiveAudio";
import PageHomeDemo5 from "containers/PageHome/PageHomeDemo5";
import PageHomeDemo6 from "containers/PageHome/PageHomeDemo6";
import PageHomeDemo7 from "containers/PageHome/PageHomeDemo7";
import PageSingleTemp4Sidebar from "containers/PageSingle/PageSingleTemp4Sidebar";
import MediaRunningContainer from "containers/MediaRunningContainer/MediaRunningContainer";
import BlogsContainer from "containers/Blogs/BlogsContainer";
import VerfiyLoginPage from "containers/PageLogin/VerifyLoginPage";
import ErrorPage from "containers/PageErrorEncounter/ErrorPage";
import { shallowEqual } from "react-redux";
import SessionSecurityWrapper from "SessionSecurityWrapper";
import TermsConditions from "containers/Terms&Conditions/TermsConditions";
import PrivacyPolicy from "containers/PrivacyPolicy/PrivacyPolicy";
import Guidelines from "containers/Guidelines/Guidelines";
import PreviewBlog from "containers/PageSingle/PreviewBlog";
export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/blog/:slug",  component: PageSingleTemp3Sidebar },
  //
  { path: "/home-header-style1", exact: true, component: PageHome },
  { path: "/home-header-style2", exact: true, component: PageHome },
  { path: "/home-header-style2-logedin", exact: true, component: PageHome },
  //
  { path: "/discover", exact: true, component: PageArchive },
  { path: "/archive-video/:slug", component: PageArchiveVideo },
  { path: "/archive-audio/:slug", component: PageArchiveAudio },
  //
  { path: "/author/:slug", component: PageAuthor },
  { path: "/author-v2/:slug", component: PageAuthorV2 },
  //
  { path: "/blog-preview", exact: true, component: PreviewBlog },
  {
    path: "/single-sidebar/:slug",
    component: PageSingleTemplate3,
  },
  {
    path: "/single-template-2/:slug",
    component: PageSingleTemplate2,
  },
  {
    path: "/single-2-sidebar/:slug",
    component: PageSingleTemp2Sidebar,
  },
  {
    path: "/single-template-3/:slug",
    component: PageSingle,
  },
  {
    path: "/single-3-sidebar/:slug",
    component: PageSingleHasSidebar,
  },
  {
    path: "/single-4-sidebar/:slug",
    component: PageSingleTemp4Sidebar,
  },
  {
    path: "/single-gallery/:slug",
    component: PageSingleGallery,
  },
  {
    path: "/single-audio/:slug",
    component: PageSingleAudio,
  },
  {
    path: "/single-video/:slug",
    component: PageSingleVideo,
  },

  { path: "/search", exact: true, component: PageSearch },
  { path: "/search-v2", exact: true, component: PageSearchV2 },
  { path: "/about", exact: true, component: PageAbout },
  { path: "/contact", exact: true, component: PageContact },
  { path: "/terms-conditions", exact: true, component: TermsConditions },
  { path: "/privacy-policy", exact: true, component: PrivacyPolicy },
  { path: "/guidelines", exact: true, component: Guidelines },
  { path: "/page404", exact: true, component: Page404 },
  { path: "/errorpage", exact: true, component: ErrorPage },
  { path: "/login", exact: true, component: PageLogin },
  { path: "/signup", exact: true, component: PageSignUp },
  { path: "/forgot-pass", exact: true, component: PageForgotPass },
  { path: "/dashboard", exact: true, component: PageDashboard, auth: true },
  { path: "/subscription", exact: true, component: PageSubcription },
  { path: "/blogs", exact: true, component: BlogsContainer },
  { path: "/verification/:email", component: VerfiyLoginPage },
  //
  { path: "/home-demo-2", exact: true, component: PageHomeDemo2 },
  { path: "/home-demo-3", exact: true, component: PageHomeDemo3 },
  { path: "/home-demo-4", exact: true, component: PageHomeDemo4 },
  { path: "/home-demo-5", exact: true, component: PageHomeDemo5 },
  { path: "/home-demo-6", exact: true, component: PageHomeDemo6 },
  { path: "/home-demo-7", exact: true, component: PageHomeDemo7 },
  //
 
  
];

const Routes = () => {
  const { token } = useAppSelector(selectAuthState, shallowEqual);
  return (
    <BrowserRouter
      basename={
        import.meta.env.VITE_BASE_PATH || ''
      }
    >
      <MediaRunningContainer />

      <ScrollToTop />
      <HeaderContainer />
      <Switch>
      <SessionSecurityWrapper>
        {pages.map(({ component: Component, path, exact, auth }) => {
          if (auth) {
            return (
              <Route
                key={path}
                render={(props) => (token ? <Component {...props} />
                  : <Redirect to='/login' />
                )}
                exact={!!exact}
                path={path}
              />
            );
          }

          return (
              <Route
                key={path}
                component={Component}
                exact={!!exact}
                path={path}
              />
            );
        })}
        </SessionSecurityWrapper>
        <Route component={Page404} />
      </Switch>
      <Footer />
      {/* MEDIA */}
    </BrowserRouter>
  );
};

export default Routes;
